<script>
export default {
  metaInfo: {
    title: "SIMs Details",
  }
};
</script>

<script setup>
import { inject, onBeforeMount, defineProps, computed, onBeforeUnmount, ref } from "vue"
import { useStore } from "@/store"
import UiTable from "@/components/basic/tables/UiTable.vue"
import UiTableItemActions from "@/components/basic/tables/UiTableItemActions.vue"
import UiBasicTableLayout from "@/components/basic/layouts/UiBasicTableLayout.vue"
import UiColumnSorter from "@/components/basic/UiColumnSorter.vue"
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import UiSearchInput from "@/components/basic/form/UiSearchInput.vue"
import XSelect from "@/components/basic/XSelect.vue";
import XBtn from "@/components/basic/XBtn.vue";
import HelpButton from "@/components/basic/HelpButton.vue"
import { useSimTable } from "@/composition/sim/use-sim-table"
import { useTableHistory } from "@/composition/tables/use-table-history"
import { ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings"
import { useSimStatusApi } from "@/composition/sim/use-sim-status-api"
import { useSimsStore } from "@/composition/sim/use-sims-store"
import { useHelpButton } from "@/composition/help/use-help-button";
import { useNotifications } from "@/composition/notifications"
import UiChipsSelect from "@/components/basic/form/UiChipsSelect.vue"
import TagQueryTextField3 from "@/components/extended/TagQueryTextField3.vue"
import { simService } from "@/api"
import { createBlueprint } from "@/js/helper";
import { SIM_TYPES } from "@/composition/sim/use-sim-configuration";

const REFRESH_MS = 30000
const HELP_BTN_PREFIX = "SIMs_View"
const USE_TAG_QUERY_FIELD = JSON.parse(process.env.VUE_APP_USE_TAG_QUERY_INPUT_SIMS_VIEW)
const USE_ESIM_FEATURE = JSON.parse(process.env.VUE_APP_USE_ESIM_FEATURE) || false;

const { getHelpButtonId } = useHelpButton({ prefix: HELP_BTN_PREFIX })
const { orgTagsForSims, saveLastTagQuery, fetchActualOrgTagsForSims } = useSimsStore()
const { showNotification } = useNotifications()

const props = defineProps({
  page: {
    type: Number,
    default: 1
  },
  itemsPerPage: {
    type: Number,
    default: 25
  },
  search: {
    type: String,
    default: ""
  },
  sortBy: {
    type: String,
    default: ""
  },
  descending: {
    type: Boolean,
    default: false
  },
  full: {
    type: Boolean,
    default: false
  },
  historyFor: {
    type: Number,
    default: 0
  },
  tagQuery: {
    type: String,
    default: ""
  },
})

const store = useStore();

const isOrgaAdmin = computed(() => {
  return store.getters.isOrgaAdmin
});

const {
  simsList,
  meta,
  init,
  displayableColumns,
  isLoading,
  updateParams,
  params,
  columnsConfigList,
  updateColumns,
  createColumnsConfigList,
  filterDisplayableFields,
  actualizeSimList,
  updateCustomFieldName,
} = useSimTable({ params: props, refreshMs: REFRESH_MS });

const {
  isChangingStatus,
  changeSimStatus,
  checkDeactivatedSim,
} = useSimStatusApi();

const {
  getHistoryCols,
} = useTableHistory();

const { openDialog } = inject("DialogsRoot");

const fetchSim = async (id) => {
  try {
    return await simService.v2.getSim(id)
  } catch (e) {
    showNotification({ message: "Failed to fetch the sim. Try again later" })
    console.error(e)
    return null;
  }
};

const actualizeData = async (data) => {
  if (data.sim) {
    actualizeSimList(data.sim)
  }

  if (data.deletedFields.length) {
    filterDisplayableFields(data.deletedFields)
  }

  if (data.updatedFields) {
    for (const [key, newName] of data.updatedFields) {
      updateCustomFieldName(key, newName)
    }
  }

  if (data.sim || data.deletedFields.lenght) {
    const enabledColumns = displayableColumns.value.slice(1)
    createColumnsConfigList(enabledColumns)
  }

  await fetchActualOrgTagsForSims()
};

const openConfigDialog = async (simId) => {
  const simData = await fetchSim(simId);

  if (!simData) {
    return
  }

  const { data } = await openDialog("SIMConfigurationDialog", { simData, simType: simData.type }, { isUiDialog: true })
  actualizeData(data);
};

const openNeweSIMDialog = async () => {
  const simFromTable = simsList.value[0];
  const id = simFromTable.id;
  const simData = await fetchSim(id)

  const blueprint = createBlueprint(simData);
  const { data } = await openDialog("SIMConfigurationDialog", { simData: blueprint, isNew: true, simType: SIM_TYPES.ESIM }, { isUiDialog: true });
  actualizeData(data)
};

const updateSimStatus = async (sim) => {
  try {
    const newSim = await changeSimStatus(sim)
    actualizeSimList(newSim)
  } catch (e) {
    console.error(e)
    throw e
  }
};


// Used by TagQueryTextField3
const tagQueryValidator = async (value) => {
  try {
    await simService.v2.countSimByTagQuery(value)
    return ""
  } catch (_err) {
    return _err.message
  }
};

// Used by UiChipsSelect with tags
const tagsFilter = computed({
  get() {
    if (params.value.tagQuery.length) {
      return params.value.tagQuery.split(" && ")
    }
    return []
  },

  set(tags) {
    const tagQuery = tags.join(" && ")
    updateParams({ ...params.value, tagQuery })
  },
});

const isAddEsimDisabled = ref(false);

onBeforeMount(async () => {
  const [, tagResponse] = await Promise.all([
    init(),
    // to find out, whether any sim exists
    simService.v2.countSimByTagQuery("")
  ]);
  isAddEsimDisabled.value = !tagResponse.count;
});

onBeforeUnmount(() => {
  saveLastTagQuery(params.value.tagQuery)
});

</script>

<template>
  <UiBasicTableLayout
    class="sim-view"
  >
    <template #page-headline>
      <div
        class="sim-view__head"
      >
        <h2 class="sim-view__title">
          <span
            class="sim-view__title-txt"
          >
            SIMs

            <span class="sim-view__count">
              <template v-if="isOrgaAdmin && !isLoading">
                ({{ meta.totalCount }})
              </template>
            </span>
          </span>

          <XBtn
            v-if="USE_ESIM_FEATURE"
            text="Add embedded sim profile"
            color="primary"
            @click="() => openNeweSIMDialog()"
            :disabled="isAddEsimDisabled"
          />

          <span
            :data-help="getHelpButtonId('create_new_sim')"
          >
            <HelpButton
              :help="getHelpButtonId('create_new_sim')"
            />
          </span>
        </h2>

        <TagQueryTextField3
          v-if="USE_TAG_QUERY_FIELD"
          class="sim-view__tags-filter"
          label="Tag query"
          :tags="orgTagsForSims"
          :validator="tagQueryValidator"
          :model-value="params.tagQuery"
          @update:modelValue="(v) => updateParams({ ...params, tagQuery: v })"
          is-debounced
          error-placement="left"
        />

        <UiChipsSelect
          v-else
          class="sim-view__tags-filter"
          label="Tag filter"
          :options="orgTagsForSims"
          :model-value.sync="tagsFilter"
          no-wrap
        />

        <UiSearchInput
          class="sim-view__search"
          :model-value="params.search"
          placeholder="Search"
          is-debounced
          @update:modelValue="(s) => updateParams({ ...params, search: s })"
        />

        <UiColumnSorter
          v-if="isOrgaAdmin"
          :columns="columnsConfigList"
          @update:columns="(cols) => updateColumns(cols)"
        />
      </div>
    </template>

    <template #table-slot>
      <UiTable
        :is-loading="isLoading"
        :sortBy="params.sortBy"
        :sortDesc="params.descending"
        :columns="displayableColumns"
        :items="simsList"
        with-horizontal-scroll
        :check-disabled-row="(sim) => checkDeactivatedSim(sim)"
        @update:sortBy="(colKey) => updateParams({ ...params, sortBy: colKey })"
        @update:sortDesc="(desc) => updateParams({ ...params, descending: desc })"
      >
        <template #cell(actions)="{ item }">
          <div class="sim-view__actions">
            <UiTableItemActions
              :actions="['activation', 'history']"
              class="sim-view__action-box"
            >
              <template #action(activation)>
                <button
                  class="sim-view__action-btn"
                  @click="() => updateSimStatus(item)"
                  type="button"
                >
                  <span v-if="!checkDeactivatedSim(item)">
                    <v-progress-circular
                      v-if="isChangingStatus"
                      indeterminate
                      color="primary"
                      size="24"
                      width="3"
                    />

                    <v-icon v-else>mdi-toggle-switch</v-icon>

                    <span>
                      Deactivate
                    </span>
                  </span>

                  <span v-else>
                    <v-progress-circular
                      v-if="isChangingStatus"
                      indeterminate
                      color="primary"
                      size="24"
                      width="3"
                    />

                    <v-icon v-else>mdi-toggle-switch-off</v-icon>

                    <span>
                      Activate
                    </span>
                  </span>
                </button>
              </template>

              <template #action(history)>
                <router-link
                  class="sim-view__action-btn"
                  type="button"
                  :to="{ name: 'sim-history', params: { id: item.id } }"
                >
                  <span>
                    <v-icon>mdi-history</v-icon>

                    <span class="sim-view__action-txt">
                      History
                    </span>
                  </span>

                  <!-- should be as a separate component -->
                  <v-tooltip right>
                    <template #activator="{on, attrs}">
                      <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>

                    <table class="sim-view__history-tooltip-table">
                      <tr>
                        <th colspan="2">
                          Version information
                        </th>
                      </tr>

                      <tr
                        v-for="col of getHistoryCols(item.history)"
                        :key="col.key"
                      >
                        <th>{{ col.name }}</th>
                        <td>{{ col.formatter(item.history[col.key]) }}</td>
                      </tr>
                    </table>
                  </v-tooltip>
                </router-link>
              </template>
            </UiTableItemActions>
          </div>
        </template>

        <template #cell(name)="{ item: sim }">
          <button
            class="sim-view__name-btn"
            @click="() => openConfigDialog(sim.id)"
          >
             <v-icon
              title="Physical SIM"
              v-if="sim.type === SIM_TYPES.CARD"
            >
              mdi-sim-outline
            </v-icon>

            <v-icon
              title="eSIM"
              v-else-if="sim.type === SIM_TYPES.ESIM"
            >
              mdi-qrcode-scan
            </v-icon>

            <v-icon
              title="Inserted in an explorer SIM"
              v-else-if="sim.type === SIM_TYPES.LOCAL"
            >
              mdi-home-floor-l
            </v-icon>

            <v-icon
              title="eSIM Wallet"
              v-else
            >
              mdi-wallet-outline
            </v-icon>

            {{ sim.name }}
          </button>
        </template>

        <template #cell(tags)="{ cellValue }">
          <div class="sim-view__tags-cell" v-html="cellValue.join(' <wbr> ')" />
        </template>

        <template #cell(user)="{ cellValue }">
          <v-tooltip top>
            <template #activator="{ attrs, on }">
              <span class="sim-view__owner-first-name" v-bind="attrs" v-on="on">
                {{ cellValue.firstName }}
              </span>
            </template>

            <span>
              {{ `${cellValue.firstName} ${cellValue.lastName}` }}
            </span>
          </v-tooltip>
        </template>
      </UiTable>
    </template>

    <template #page-footer>
      <div
        v-if="meta.page"
        class="sim-view__footer"
      >
        <PaginationBlock
          :total-count="meta.totalCount"
          :current-page="meta.page"
          :items-per-page="meta.itemsPerPage"
          @update:current-page="(pageN) => updateParams({ ...params, page: pageN })"
        />

        <XSelect
          class="sim-view__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="meta.itemsPerPage"
          @input="(_iPerPage) => updateParams({ ...params, itemsPerPage: _iPerPage})"
          label="Items per page"
          :autocomplete="false"
        />
      </div>
    </template>
  </UiBasicTableLayout>
</template>

<style lang="scss">
:root {
  --v-application-wrap-display: grid;
  --v-application-wrap-overflow: hidden;
  --main-v3-max-height: none;
}

.sim-view {
  $root: &;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &__count {
    display: inline-block;
    min-width: 40px;
  }

  &__tags-filter {
    max-width: 300px;
    width: 100%;
    --input-slot-width: 300px;
  }

  &__title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &__title-txt {
    display: inline-block;
    line-height: normal;
  }

  &__search,
  &__tags-filter {
    margin-top: -.25rem;
    align-self: flex-start;
  }

  &__name-btn {
    color: #2b5593;
    cursor: pointer;
    text-decoration: none;
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 1rem;
  }

  &__action-box {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__action-btn {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: .25rem;
    width: 100%;
    padding: 0.875rem 1rem;
    font-size: 1rem;

    &--delete {
      width: 100%;
      color: rgba(0,0,0,.54);

      &:hover {
        color: white;
        background-color: #b82519;

        #{$root}__action-txt {
          color: white;
        }
      }
    }
  }

  &__action-txt {
    color: #000;
  }

  &__history-tooltip-table {
    width: 100%;
    border-spacing: 0;
    table-layout: auto;

    th, td {
      text-align: left;
    }
  }

  &__owner-first-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__tags-cell {
    max-width: 100px;
    min-width: 150px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}
</style>

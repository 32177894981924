import { ref } from "vue"
import { simService } from "@/api"

export const DEACTIVATED_STATUS = "deactivated"

export const useSimStatusApi = () => {
  const isChangingStatus = ref(false)

  const checkDeactivatedSim = (sim) => sim.status === DEACTIVATED_STATUS

  const changeSimStatus = async (sim) => {
    isChangingStatus.value = true
    try {
      if (checkDeactivatedSim(sim)) {
        await simService.v2.activateSim(sim.id)
      } else {
        await simService.v2.deactivateSim(sim.id)
      }
      return await simService.v2.getSim(sim.id)
    } finally {
      isChangingStatus.value = false
    }
  }

  return {
    isChangingStatus,
    changeSimStatus,
    checkDeactivatedSim,
  }
}

import { unixToDateTimeString } from "@/js/general"

export const JOURNAL_INFO_COLS = [
  {
    name: "Version",
    key: "version",
    formatter: (v) => v,
    sortable: true,
  },
  {
    name: "Created by",
    key: "createdBy",
    formatter: (v) => v,
    sortable: true,
  },
  {
    name: "Created date",
    key: "createDate",
    formatter: unixToDateTimeString,
    sortable: true,
  },
  {
    name: "Modified by",
    key: "modifiedBy",
    formatter: (v) => v,
    sortable: true,
  },
  {
    name: "Modify date",
    key: "modifyDate",
    formatter: unixToDateTimeString,
    sortable: true,
  },
  {
    name: "Comment",
    key: "comment",
    formatter: (v) => v,
    sortable: true,
  },
]

export const useTableHistory = () => {
  const getHistoryCols = (itemHistory) => {
    return JOURNAL_INFO_COLS.filter((col) => Boolean(itemHistory[col.key]))
  }

  return {
    getHistoryCols,
  }
}

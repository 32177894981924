import { ref, computed } from "vue";
import { QueryWordManager } from "@acctopus-fe/query-input-parsers";

export const useQueryWordManager = (input, cursorPosition = 0) => {
  const cursorPos = ref(cursorPosition);
  const manager = ref(new QueryWordManager(input));
  const word = ref("");
  const query = computed({
    get() {
      return manager.value.input;
    },
    set(v) {
      manager.value.input = v;
    }
  });
  const observeCursor = (_curPos) => {
    cursorPos.value = _curPos;
    word.value = manager.value.getWordAtCursor(cursorPos.value);
  }

  const replaceWordAtCursor = (word) => {
    return manager.value.replaceWordAtCursor(cursorPos.value, word);
  };

  return {
    cursorPos,
    query,
    word,
    observeCursor,
    replaceWordAtCursor,
  }
}
